<template>
    <div>
        <el-dialog :visible.sync="dialogVisible" width="1200px" :close-on-click-modal="false" :close-on-press-escape="false" :before-close="handleClose">
            <div class="now">你当前正在</div>
            <el-steps :active="active" align-center>
                <el-step title="商品信息"></el-step>
                <el-step title="宣传图"></el-step>
                <el-step title="预算承诺"></el-step>
                <el-step title="服务详情"></el-step>
                <el-step title="预览"></el-step>
                <el-step title="上架保存"></el-step>
            </el-steps>
            <div class="state">
                <img src="../../assets/images/serve/gth.png" alt="">
                正在 <span>修改商品服务</span>
            </div>
            <div v-show="active == 0">
                <div v-if="currter == 0">
                    <el-form style="margin-top:40px" label-position="left" label-width="100px" :model="form1" @submit.native.prevent>
                        <el-form-item label="*商品类别">
                            <div class="code">
                                <el-select v-model="form1.type" style="width:490px" placeholder="请选择需求类别">
                                    <el-option v-for="item in classList" :key="item.id" :label="item.name" :value="item.id"></el-option>
                                </el-select>
                            </div>
                        </el-form-item>
                        <el-form-item label="*商品标题">
                            <div class="code">
                                <input style="width:490px" v-model="form1.name">
                            </div>
                        </el-form-item>
                        <el-form-item label="*服务简介">
                            <el-input style="padding: 10px 10px;" :autosize="{ minRows: 4, maxRows: 10}" type="textarea" placeholder="" v-model="form1.introduce"></el-input>
                        </el-form-item>
                        <div class="areas">
                            <div class="lable">服务地区选项（可多选）</div>
                            <div>
                                <el-tag v-for="(item,index) in addr" :key="index">
                                    {{item}}
                                </el-tag>
                            </div>
                        </div>
                        <div class="blue" @click="currter = 1">点击进行多选</div>
                        <div class="areas">
                            <div class="lable">服务规格 （可多选）(用企业人数)</div>
                            <div class="tags">
                                <div class="check-box">
                                    <el-checkbox class="check-item" v-model="val.status" :label="val.name" v-for="val in speciList" :key="val.id" name="type"></el-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="areas">
                            <div class="lable">有效期（可多选）</div>
                            <div class="tags">
                                <div class="check-box">
                                    <el-checkbox class="check-item" v-model="val.status" :label="val.name" v-for="val in timeList" :key="val.id" name="type"></el-checkbox>
                                </div>
                            </div>
                        </div>
                        <div class="privacy">你的隐私正在保护中...</div>
                        <!-- 底部按钮 -->
                        <div class="foot-btn">
                            <!-- <div @click="pre">返回上一步</div> -->
                            <el-button class="f-btn" @click="next(0)">下一步</el-button>
                        </div>
                    </el-form>
                </div>
                <!-- 服务地区选择 -->
                <div v-else>

                    <div class="goback" @click="currter = 0">
                        <i class="el-icon-arrow-left"></i>返回
                    </div>
                    <div class="city">
                        <div class="blue">服务地区选择</div>
                        <div class="check" v-for="item in proList" :key="item.id">
                            <div class="check-head">
                                <el-checkbox class="check-item" @change="change(item)" v-model="item.status"></el-checkbox><span>{{item.province}}</span>
                            </div>
                            <div class="check-box">
                                <el-checkbox class="check-item" @change="checkboxChage(item)" v-model="val.status" :label="val.city" v-for="val in item.cities" :key="val.id" name="type"></el-checkbox>
                            </div>
                        </div>
                    </div>
                    <div class="privacy">你的隐私正在保护中...</div>
                    <!-- 底部按钮 -->
                    <div class="foot-btn">
                        <el-button class="f-btn" @click="selectCity">确认</el-button>
                    </div>
                </div>
            </div>
            <div v-show="active == 1">
                <div class="lable-title">封面图(支持jpg、png格式图片)</div>
                <div class="publicity">
                    <el-upload :limit="1" :action="action" :file-list="fileList" :on-success="beforeLoad" list-type="picture-card" :on-remove="removeImg">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="lable-title">轮播图(支持jpg、png格式图片)</div>
                <div class="publicity">
                    <el-upload :limit="5" :action="action" :file-list="fileList2" :on-success="beforeLoadL" list-type="picture-card" :on-remove="removeImgL">
                        <i class="el-icon-plus"></i>
                    </el-upload>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="f-btn" @click="next(1)">确定上传</el-button>
                </div>
            </div>
            <div v-show="active == 2">
                <div class="areas">
                    <div class="lable">*服务预算</div>
                    <div class="code">
                        <input style="width:490px" placeholder="服务预算" v-model="form1.budget">
                    </div>
                </div>
                <div class="areas">
                    <div class="lable">*服务数量最大额</div>
                    <div class="code">
                        <el-radio-group v-model="getNum" @change="numChange">
                            <el-radio :label="0">不限</el-radio>
                            <el-radio :label="1">自定义:</el-radio>
                        </el-radio-group>
                        <input v-if="getNum ==1 " type="number" style="width:210px" v-model="setNum">
                    </div>
                </div>
                <div class="areas">
                    <div class="lable">*服务数量最大额</div>
                    <div class="code">
                        订单平均完成周期：
                        <input style="width:210px" v-model="form1.expectDay"> 天
                    </div>
                </div>
                <div class="areas">
                    <div class="lable"></div>
                    <div class="code">
                        订单平均响应周期：
                        <input style="width:210px" v-model="form1.answerHour"> 小时
                    </div>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="f-btn" @click="next(2)">确定上传</el-button>
                </div>
            </div>
            <div v-show="active == 3">
                <vue-editor v-model="form1.content" id="editor" useCustomImageHandler @image-added="handleImageAdded" />
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="f-btn" @click="next(3)">下一步</el-button>
                </div>
            </div>
            <div v-if="active== 4">
                <div class="detail">
                    <div class="detail-img">
                        <img :src="$util.host + form1.cover" alt="">
                    </div>
                    <div class="detail-info">
                        <div class="title">{{form1.name}}</div>
                        <div class="budget">
                            <div>预算</div>
                            <div class="red">￥{{form1.budget}}</div>
                            <div>访问量 <span>0</span></div>
                        </div>
                        <div class="shop-name">
                            <span>店铺</span>
                            {{shopName}}
                        </div>
                        <div class="introduction">
                            服务简介
                            <div>{{form1.introduce}}</div>
                        </div>
                        <div class="cycle">
                            <div>订单平均完成周期 <span>{{form1.expectDay}}天</span></div>
                            <div class="shu"></div>
                            <div>订单平均响应周期 <span>{{form1.answerHour}}小时</span></div>
                        </div>
                        <div class="area">
                            <div class="lable">服务地区</div>
                            <div class="tags">
                                <div v-for="(item,index) in addr" :class="index==0?'active':''" :key="index">{{item}}</div>
                            </div>
                        </div>
                        <div class="area">
                            <div class="lable">服务规格(用企业人数)</div>
                            <div class="tags">
                                <div v-for="(item,index) in normsList" :class="index==0?'active':''" :key="item.id">{{item}}</div>
                            </div>
                        </div>
                        <div class="area">
                            <div class="lable">有效期</div>
                            <div class="tags">
                                <div v-for="(item,index) in termList" :class="index==0?'active':''" :key="item.id">{{item}}</div>
                            </div>
                        </div>
                        <div class="area">
                            <div class="lable">数量</div>
                            <el-input-number :disabled="true" v-model="num" :min="1" :max="10"></el-input-number>
                        </div>
                        <div class="btn">
                            <el-button class="loser-btn">购买服务</el-button>
                            <el-button class="loser-btn">微信咨询</el-button>
                        </div>
                    </div>
                </div>
                <div class="tabs">
                    <el-tabs v-model="activeName" @tab-click="handleClick">
                        <el-tab-pane label="服务详情" name="first">
                            <!-- <div class="shop-detail">
                              
                            </div> -->
                            <div class="shop-content" v-html="form1.content"></div>
                            <!-- <div class="shop-content"></div>
                            <div class="shop-content"></div> -->
                        </el-tab-pane>
                        <el-tab-pane label="服务评价" name="second">
                            <div class="menus">
                                <div class="box">
                                    <div>40+</div>
                                    评价
                                </div>
                                <div class="box">
                                    <div>100%</div>
                                    好评率
                                </div>
                                <div class="box">
                                    <div>100+</div>
                                    成交量
                                </div>
                            </div>
                            <div class="comment-box">
                                <div class="comment" v-for="item in 6 " :key="item">
                                    <img src="../../assets/images/home/chat.png" alt="">
                                    <div class="comment-info">
                                        <div class="user-info">
                                            <div class="name">
                                                <div> asdfghjkl</div>
                                                <el-rate v-model="star" disabled show-text :colors="colors" :texts="['极差', '失望', '一般', '满意', '好评']" text-color="#666666">
                                                </el-rate>
                                            </div>
                                            <div>2021-12-05</div>
                                        </div>
                                        <div class="comment-content">
                                            非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错
                                            非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错非常不错
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </el-tab-pane>
                    </el-tabs>
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <div @click="pre">返回上一步</div>
                    <el-button class="f-btn" @click="upload">确定上传</el-button>
                </div>
            </div>
            <div v-show="active == 5">
                <div class="no-win">
                    <img src="../../assets/images/serve/qr.png" alt="">
                    修改成功/保存成功，现在可以去<span class="red">店铺管理</span>看看
                </div>
                <div class="privacy">你的隐私正在保护中...</div>
                <!-- 底部按钮 -->
                <div class="foot-btn">
                    <el-button class="f-btn" @click="handleClose">确认</el-button>
                    <span class="keep" @click="copy">保存到草稿箱</span>
                </div>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { VueEditor } from "vue2-editor";
import axios from "axios";
export default {
    components: { VueEditor },
    name: 'QppModifydetail',
    props: {
        dialogVisible: Boolean,
        good: Object,
        shopName: String
    },
    data() {
        return {
            active: 0, //当前进度
            currter: 0, //当前下标选择
            getNum: 0, //获取数量
            setNum: 0, //修改数量
            form1: { //修改的参数
                goodsId: null,
                type: '', //商品分类id
                name: '', //服务名称
                introduce: '', //简介
                addr: '', //服务地址 ,拼接
                norms: '', //规格 ,拼接
                term: '', //有效期 ,拼接
                budget: '', //预算
                num: 0, //商品数量 不限制传0
                cover: '', //封面图
                banner: '', //轮播图 ,拼接
                expectDay: '', //预计完成天数
                answerHour: '', //平均相应时间 小时
                content: '', //内容富文本
                state: 1, //1直接上架 2保存到草稿箱
            },
            form: {
                money: '',
                phone: '',
                type: false,
                smaCode: '',
                resource: ''
            }, //废弃
            classList: [], //所有分类
            proList: [], //所有省市
            addr: [],// 服务地区展示数据
            normsList: [],// 规格列表
            termList: [],// 有效期列表
            speciList: [
                { id: 0, name: '1-10人', status: false },
                { id: 1, name: '11-25人', status: false },
                { id: 2, name: '26-45人', status: false },
                { id: 3, name: '45-65人', status: false },
                { id: 4, name: '66-85人', status: false },
                { id: 5, name: '86-125人', status: false },
                { id: 6, name: '126-175人', status: false },
                { id: 7, name: '176-275人', status: false },
                { id: 8, name: '276-425人', status: false },
                { id: 9, name: '426-625人', status: false },
                { id: 10, name: '626-875人', status: false },
            ],
            timeList: [
                { id: 0, name: '1年', status: false },
                { id: 1, name: '2年', status: false },
                { id: 2, name: '3年', status: false },
                { id: 3, name: '4年', status: false },
                { id: 4, name: '5年', status: false },
                { id: 5, name: '10年', status: false },
                { id: 6, name: '50年', status: false },
                { id: 7, name: '永久', status: false },
            ],
            action: this.$util.upload,
            fileList: [], //回显图片列表
            fileList2: [], //回显图片列表
            num: 1,
            activeName: 'first',
            current: 0,
            star: 5,
            colors: ['#E40012', '#E40012', '#E40012'],
        };
    },

    mounted() {
        this.allClass()
        this.getPro()
        console.log(this.good);
        // 回显展示传来数据进行修改
        this.form1.goodsId = this.good.id
        this.form1.type = this.good.type
        this.form1.name = this.good.name
        this.form1.introduce = this.good.introduce
        this.form1.cover = this.good.cover
        this.form1.banner = this.good.banner
        this.form1.budget = this.good.budget
        this.form1.expectDay = this.good.expectDay
        this.form1.answerHour = this.good.answerHour
        this.form1.content = this.good.content
        this.addr = this.good.address
        this.form1.addr = this.addr.join(',')
        this.normsList = this.good.norms
        this.termList = this.good.term
        // this.fileList.push({ url: this.$util.host + this.good.cover })
        this.fileList.push()
        this.good.banner.split(',').forEach(item => {
            this.fileList2.push({ name: "1", url: this.$util.host + item })
            this.fileList2.push()
        })
        if (this.good.num == -1) {
            this.getNum = 0
        } else {
            this.getNum = 1
        }
        // 获取规格
        this.speciList.forEach(item => {
            this.good.norms.forEach(el => {
                if (el == item.name) {
                    item.status = true
                }
            })
        })

        this.timeList.forEach(item => {
            this.good.term.forEach(el => {
                if (el == item.name) {
                    item.status = true
                }
            })
        })

    },

    methods: {
        handleClose() {
            this.$emit('close');

        },
        // 获取所有省市
        getPro() {
            this.$util.post('/address/all').then(res => {
                for (let key in res.data) {
                    this.proList.push(res.data[key])
                }
                this.proList.unshift({ id: 0, province: '全国', cities: [] })
                this.proList.forEach(item => {
                    this.$set(item, 'status', false)
                    if (item.cities) {
                        item.cities.forEach(val => {
                            this.$set(val, 'status', false)
                        })
                    }
                })
            })
        },
        // 获取所有分类
        allClass() {
            this.$util.post('/shop/allClassify').then(res => {
                this.classList = res.data
            })
        },
        change(item) {
            if (!item.status) {
                item.cities.forEach(el => {
                    el.status = false
                })
            }
        },
        // 市级checkbox 改变
        checkboxChage(item) {
            let state = item.cities.some(el => {
                if (el.status) {
                    return true
                }
            })
            if (state) {
                item.status = true
            }


        },
        // 选择城市
        selectCity() {

            let list = this.proList.filter(item => {
                return item.status
            })
            console.log(list);
            if (list.length == 0) {
                return this.$message.error("至少选择一个服务地区");
            }
            let addrList = []
            list.forEach(item => {
                let some = item.cities.filter(el => {
                    return el.status
                })
                if (some.length == 0) {
                    addrList.push(item.province)
                } else {
                    some.forEach(val => {
                        addrList.push(item.province + val.city)
                    })
                }
            })
            this.addr = addrList
            this.form1.addr = addrList.join(',')
            this.currter = 0


        },
        // 上传
        beforeLoad(response, file, fileList) {
            // console.log(response);
            // console.log(file);
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form1.cover = imgs.join(',')
        },
        removeImg(file, fileList) {
            let imgs = []
            imgs = fileList.map(item => {
                return item.url
            })
            this.form1.cover = imgs.join(',')
        },

        // 轮播上传
        beforeLoadL(response, file, fileList) {
            let imgs = []
            imgs = fileList.map(item => {
                return item.response.data.url
            })
            this.form1.banner = imgs.join(',')
        },
        removeImgL(file, fileList) {
            let imgs = []
            imgs = fileList.map(item => {
                return item.url
            })
            this.form1.banner = imgs.join(',')
            console.log(this.form1);

        },
        // 服务数量最大值
        numChange(num) {
            this.setNum = ''
            if (num == 0) {
                this.form1.num = 0
            } else {
            }

        },
        // 富文本上传图片
        handleImageAdded(file, Editor, cursorLocation, resetUploader) {
            // An example of using FormData
            // NOTE: Your key could be different such as:
            // formData.append('file', file)

            var formData = new FormData();

            formData.append('file', file);
            console.log(new FormData());
            axios({
                url: this.$util.host + "/file/upload",
                method: "POST",
                data: formData
            })
                .then(result => {
                    let url = this.$util.host + result.data.data.url; // Get url from response
                    console.log(url);

                    Editor.insertEmbed(cursorLocation, "image", url);
                    resetUploader();
                })
                .catch(err => {
                    console.log(err);
                });


        },

        handleClick(tab) {
            this.current = tab.index
        },
        // 进行下一步进行接口调用
        next(num) {
            if (num == 0) {
                if (!this.form1.type || this.form1.name == "" || this.form1.introduce == "" || this.addr.length == 0) {
                    return this.$message.error("商品信息必须填完不能留空~");
                }
                // this.form1.norms = normsList.join(',')
                let list = this.speciList.filter(item => {
                    return item.status
                })
                let normsList = []
                list.forEach(item => {
                    normsList.push(item.name)
                })
                this.normsList = normsList
                let list2 = this.timeList.filter(item => {
                    return item.status
                })
                let termList = []
                list2.forEach(item => {
                    termList.push(item.name)
                })
                this.termList = termList
                this.form1.norms = normsList.join(',')
                this.form1.term = termList.join(',')
                this.active = ++num

            } else if (num == 1) {
                this.active = ++num
            } else if (num == 2) {
                if (this.getNum == 0) {
                    this.form1.num = 0
                } else {
                    this.form1.num = this.setNum
                }
                this.active = ++num
            } else if (num == 3) {
                this.active = ++num
                console.log(this.form1);

            }



        },
        pre() {
            this.active--
        },
        // 上传
        upload() {
            this.$util.post('/shop/newGoods', this.form1).then(res => {
                if (res.code == 200) {
                    this.active++
                } else {
                    this.$message.error(res.message);
                }
            })
        },
        // 报错到草稿箱
        copy() {
            this.form1.state = 2
            this.$util.post('/shop/newGoods', this.form1).then(res => {
                if (res.code == 200) {
                    this.handleClose()
                } else {
                    this.$message.error(res.message);
                }
            })
        }
    },
};
</script>

<style lang="scss" scoped>
// 弹出框

.now {
    font-size: 18px;
    color: #999999;
    margin-bottom: 30px;
}
/deep/.el-step__title.is-wait {
    // background: #e40012;
    color: #666;
}
/deep/.el-step__title.is-process {
    color: #e40012;
}
/deep/.el-step__icon.is-text {
    color: #666666;
    width: 36px;
    height: 36px;
}
/deep/.el-step__head.is-process {
    border-color: #e40012;
}
/deep/.el-step__title.is-finish {
    color: #e40012;
}
/deep/.el-step__head.is-finish {
    border-color: #e40012;
}
.red {
    color: #e40012 !important;
}
.look {
    display: flex;
    align-items: center;
    color: #222;
    font-size: 16px;
    margin: 40px 0;
    div {
        color: #999999;
        margin-right: 20px;
    }
}

.checked {
    margin-top: 30px;
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #666666;
    span {
        color: #2ea7e0;
    }
}
.state {
    padding: 20px 0 10px;
    border-bottom: 1px solid #eeeeee;
    display: flex;
    align-items: center;
    font-size: 22px;
    color: #333333;
    span {
        color: #e40012;
    }
    img {
        width: 25px;
        height: 25px;
        margin-right: 10px;
    }
}
.code {
    height: 48px;
    // border: 1px solid #dcdfe6;
    display: flex;
    align-items: center;
    // width: 500px;
    input {
        height: 100%;
        // border: none;
        outline: none;
        padding: 0 15px;
        border-radius: 4px;
        font-size: 16px;
        border: 1px solid #eeeeee;
    }
    button {
        background-color: transparent;
        border: none;
        color: #e40012;
        font-weight: bold;
        font-size: 14px;
    }
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type="number"] {
    -moz-appearance: textfield;
}
input::-webkit-input-placeholder {
    color: #c0c4cc;
}
input::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: #c0c4cc;
}
input:-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: #c0c4cc;
}
input:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: #c0c4cc;
}
.blue {
    color: #2ea7e0;
    font-size: 16px;
    cursor: pointer;
}
.areas {
    display: flex;
    align-items: center;
    font-size: 14px;
    margin-bottom: 10px;
    margin-top: 20px;
    .lable {
        color: #999;
        margin-right: 40px;
        min-width: 150px;
        text-align: right;
    }
    /deep/.el-tag {
        color: #222222;
        background: #fff;
        border: none;
        cursor: pointer;
        margin-right: 10px;
    }
    /deep/.el-tag .el-tag__close {
        color: #222;
        line-height: 19px;
    }
    /deep/.el-tag .el-tag__close:hover {
        background: transparent;
    }
}
.tags {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    line-height: normal !important;
    .active {
        padding: 6px 14px;
        border: 1px solid #e40012;
        color: #e40012;
    }
}
.check-box {
    display: flex;
    align-items: center;
    flex: 1;
    // justify-content: space-between;
    flex-wrap: wrap;
    font-size: 16px;
    color: #222;
    .check-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        min-width: 16%;
    }
}
.privacy {
    font-size: 16px;
    color: #00a71c;
    margin: 30px 0 40px;
}
.foot-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 100px;
    font-size: 16px;
    cursor: pointer;
    color: #e40012;
    .f-btn {
        padding: 15px 180px;
        background: #e40012;
        color: #fff;
        font-size: 18px;
        margin-left: 40px;
        margin-right: 20px;
    }
    .keep {
        color: #2ea7e0;
        font-size: 18px;
    }
}
/deep/.el-form-item__label {
    line-height: normal;
    color: #999;
}
.publicity {
    padding: 30px 0 0;
    display: flex;
    align-items: center;
}
/deep/.el-upload--picture-card {
    width: 198px;
    height: 124px;
    line-height: 130px;
    margin-right: 90px;
}
/deep/.el-upload-list--picture-card .el-upload-list__item {
    width: 198px;
    height: 124px;
    margin-right: 90px;
    margin-bottom: 50px;
}
.content-info {
    padding: 30px;
    background: #f6f6f6;
    color: #222;
    font-size: 14px;
    max-height: 1000px;
    overflow-y: visible;
    line-height: normal;
}
.codes {
    display: flex;
    align-items: center;
    margin-top: -10px;
    span {
        margin: 0 10px;
    }
}
.detail {
    // width: 1160px;
    padding: 20px;
    margin: 30px auto;
    border: 1px solid #eeeeee;
    display: flex;
}
.detail-img {
    width: 720px;
    height: 554px;
    // background: red;
    margin-right: 20px;
    img {
        width: 100%;
        height: 100%;
    }
}
.detail-info {
    flex: 1;
    .title {
        font-size: 22px;
        color: #222222;
        font-weight: bold;
    }
    .budget {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 30px 0 20px;
        color: #999999;
        font-size: 14px;
        font-weight: bold;
        span {
            color: #222222;
            font-size: 16px;
        }
        .red {
            color: #e40012;
        }
    }
    .shop-name {
        color: #222222;
        font-size: 16px;
        span {
            font-size: 14px;
            color: #999;
            margin-right: 60px;
        }
    }
    .introduction {
        display: flex;
        color: #999999;
        font-size: 14px;
        padding: 20px 0 30px;
        border-bottom: 1px solid #f1f1f1;
        div {
            flex: 1;
            margin-left: 35px;
            color: #222;
            font-size: 12px;
        }
    }
    .cycle {
        display: flex;
        font-size: 16px;
        color: #222;
        align-items: center;
        justify-content: space-between;
        margin: 20px 0;
        font-weight: bold;
        .shu {
            width: 2px;
            height: 24px;
            background: #f1f1f1;
        }
        span {
            color: #e40012;
            margin-left: 10px;
        }
    }
    .area {
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #999;
        margin-bottom: 20px;
        .lable {
            width: 80px;
            margin-right: 30px;
        }
        .tags {
            flex: 1;
            display: flex;
            flex-wrap: wrap;
            div {
                padding: 6px 14px;
                border: 1px solid #dbdbdb;
                margin-right: 10px;
                border-radius: 4px;
                margin-bottom: 10px;
            }
            .active {
                padding: 6px 14px;
                border: 1px solid #e40012;
                color: #e40012;
            }
        }
    }
    .btn {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
    }
}
.tabs {
    // width: 1200px;
    margin: 0 auto;
}
/deep/.el-tabs__item.is-active {
    color: #e40012;
}
/deep/.el-tabs__active-bar {
    background-color: #e40012 !important;
    width: 80px !important;
}
/deep/.el-tabs__item {
    font-size: 22px;
    color: #666666;
    width: 120px;
    text-align: center;
}
/deep/.el-tabs__item:hover {
    color: #e40012;
}
.shop-detail {
    padding: 20px;
    border: 1px solid #eeeeee;
    font-size: 14px;
    color: #222;
    margin-bottom: 30px;
}
.shop-content {
    // border: 1px solid #707070;
    // width: 1200px;
    // height: 400px;
    margin-top: 40px;
}
.menus {
    display: flex;
    justify-content: space-between;
    margin: 30px 0 50px;
    .box {
        width: 380px;
        color: #e40012;
        padding: 40px 0;
        font-size: 24px;
        text-align: center;
        // background: #e40012;
        border: 1px solid #e40012;
        div {
            font-size: 38px;
            font-weight: bold;
            margin-bottom: 15px;
        }
    }
}
.comment-box {
    // width: 1200px;
    margin: 0 auto 20px;
}
.comment {
    display: flex;
    padding: 30px 20px;
    border-bottom: 1px solid #f1f1f1;
    img {
        width: 82px;
        height: 82px;
    }
    .comment-info {
        flex: 1;
        margin-left: 20px;
        font-size: 14px;
        color: #999999;
        .user-info {
            display: flex;
            align-items: center;
            justify-content: space-between;
            color: #222;
            .name {
                display: flex;
                align-items: center;
                div {
                    margin-right: 20px;
                }
            }
        }
    }
}
.comment-content {
    margin: 13px 0 20px;
    font-size: 14px;
    color: #666;
}
.comment-serve {
    margin-bottom: 10px;
    span {
        color: #e40012;
    }
}
.no-win {
    text-align: center;
    font-size: 18px;
    color: #999999;
    margin-top: 150px;
    margin-bottom: 70px;
    span {
        color: #e40012;
    }
    img {
        width: 56px;
        height: 56px;
        display: block;
        margin: 0 auto 20px;
    }
}
.goback {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #222;
    margin: 30px 0;
    cursor: pointer;
}
.title-t {
    font-size: 16px;
    color: #999999;
}
.check {
    margin-top: 25px;
    .check-head {
        display: flex;
        align-items: center;
        padding: 13px;
        background: #f6f6f6;
        color: #222;
        span {
            margin-left: 10px;
        }
        font-size: 18px;
    }
    .check-box {
        display: flex;
        align-items: center;
        // justify-content: space-between;
        flex-wrap: wrap;
        font-size: 16px;
        color: #222;
        .check-item {
            display: flex;
            align-items: center;
            margin-top: 30px;
            min-width: 16%;
        }
    }
}
.checkbox {
    span {
        margin-left: 10px;
    }
    font-size: 18px;
    margin-top: 20px;
}
.city {
    min-height: 700px;
    overflow-y: auto;
    max-height: 800px;
}
.lable-title {
    font-size: 16px;
    margin-top: 30px;
    color: #999999;
}
.shop-content {
    /deep/ img {
        max-width: 100%;
    }
}
</style>