<template>
  <div>
    <div class="head-main">
      <div class="keep" v-if="state != 2" @click="copy">保存到草稿箱</div>
      <el-button v-if="state == 0" class="head-bt" @click="rack">下架</el-button>
      <el-button v-else class="head-bt" @click="onShelves">上架</el-button>
      <el-button class="head-btn" @click="dialogVisible = true">修改</el-button>
    </div>
    <div class="detail">
      <div class="detail-img">
        <el-carousel height="550px" arrow="never" trigger="click">
          <el-carousel-item v-for="item in bannerList" :key="item.id">
            <div class="swiper">
              <img :src="$util.host + item" alt="" />
            </div>
          </el-carousel-item>
        </el-carousel>
        <!-- <img :src="$util.host + good.cover" alt=""> -->
      </div>
      <div class="detail-info">
        <div class="title">{{ good.name }}</div>
        <div class="budget">
          <div>预算</div>
          <div class="red">￥{{ good.budget }}</div>
          <div>
            访问量 <span>{{ good.views }}</span>
          </div>
        </div>
        <div class="shop-name">
          <span>店铺</span>
          {{ shopName }}
        </div>
        <div class="introduction">
          服务简介
          <div>{{ good.introduce }}</div>
        </div>
        <div class="cycle">
          <div>
            订单平均完成周期 <span>{{ good.expectDay }}天</span>
          </div>
          <div class="shu"></div>
          <div>
            订单平均响应周期 <span>{{ good.answerHour }}小时</span>
          </div>
        </div>
        <div class="area">
          <div class="lable">服务地区</div>
          <div class="tags">
            <div
              v-for="(item, index) in good.address"
              :class="index == 0 ? 'active' : ''"
              :key="item.id"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="area" v-if="good.norms[0] != ''">
          <div class="lable">服务规格(用企业人数)</div>
          <div class="tags">
            <div
              v-for="(item, index) in good.norms"
              :class="index == 0 ? 'active' : ''"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="area" v-if="good.term[0] != ''">
          <div class="lable">有效期</div>
          <div class="tags">
            <div
              v-for="(item, index) in good.term"
              :class="index == 0 ? 'active' : ''"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </div>
        <div class="area">
          <div class="lable">数量</div>
          <el-input-number
            :disabled="true"
            v-model="num"
            :min="1"
            :max="10"
          ></el-input-number>
        </div>
        <div class="btn">
          <el-button class="loser-btn">购买服务</el-button>
          <el-button class="loser-btn">微信咨询</el-button>
        </div>
      </div>
    </div>
    <div class="tabs">
      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="服务详情" name="first">
          <div class="shop-content" v-html="good.content"></div>
        </el-tab-pane>
        <el-tab-pane label="服务评价" name="second">
          <div class="menus">
            <div class="box">
              <div>+{{ evaluateNum }}</div>
              评价
            </div>
            <div class="box">
              <div>{{ shopInfo.evaluateRate }}%</div>
              好评率
            </div>
            <div class="box">
              <div>+{{ good.goodsSize }}</div>
              成交量
            </div>
          </div>
          <div class="comment-box">
            <div class="comment" v-for="item in evaluateList" :key="item">
              <img :src="$util.host + item.userHeadPic" alt="" />
              <div class="comment-info">
                <div class="user-info">
                  <div class="name">
                    <div>{{ item.userName }}</div>
                    <el-rate
                      v-model="item.level"
                      disabled
                      show-text
                      :colors="colors"
                      :texts="['极差', '失望', '一般', '满意', '好评']"
                      text-color="#666666"
                    >
                    </el-rate>
                  </div>
                  <div>{{ item.createTime.slice(0, item.createTime.length - 9) }}</div>
                </div>
                <div class="comment-content">
                  {{ item.txt }}
                </div>
              </div>
            </div>
          </div>
        </el-tab-pane>
      </el-tabs>
    </div>

    <SetModifyDetail
      v-if="dialogVisible"
      :good="good"
      :shopName="shopName"
      :dialogVisible="dialogVisible"
      @close="close"
    />
  </div>
</template>

<script>
import ModifyDetail from "../../components/manage/modifyDetail.vue";
import SetModifyDetail from "../../components/manage/setModifyDetail.vue";
export default {
  components: { ModifyDetail, SetModifyDetail },
  data() {
    return {
      areaList: [
        { id: 0, name: "全国" },
        { id: 1, name: "北京" },
        { id: 2, name: "广东深圳" },
      ], //舍弃
      speciList: [
        { id: 0, name: "1-10人" },
        { id: 1, name: "11-25人" },
        { id: 2, name: "26-45人" },
        { id: 3, name: "26-45人" },
        { id: 4, name: "26-45人" },
        { id: 5, name: "26-45人" },
      ], //舍弃
      timeList: [
        { id: 0, name: "1年" },
        { id: 1, name: "1年" },
        { id: 2, name: "1年" },
        { id: 3, name: "1年" },
        { id: 4, name: "1年" },
      ], //舍弃
      num: 1,
      activeName: "first", //当前下标 详情  评论
      current: 0, //当前下标
      star: 5,
      shopName: this.$route.query.shopName,
      colors: ["#E40012", "#E40012", "#E40012"],
      dialogVisible: false, //修改商品弹出层
      id: this.$route.query.id, //当前的商品ID
      good: {}, //商品的详情信息
      state: this.$route.query.num, //当前的转态   0位上架商品 企业另类
      bannerList: "",
      evaluateList: "",
      evaluateNum: "",
      shopInfo: "",
    };
  },
  mounted() {
    this.allData();
  },
  methods: {
    getShopInfo() {
      this.$util
        .post("/demandPurchase/shopDet", {
          shopId: this.good.shopId,
        })
        .then((res) => {
          this.shopInfo = res.data.shop;
        });
    },
    getEvaluate() {
      this.$util
        .post("/demandPurchase/evaluateOrderList", {
          goodsId: this.id,
          shopId: this.good.shopId,
        })
        .then((res) => {
          console.log(res);
          this.evaluateList = res.data;
          this.evaluateNum = res.pager.totalRows;
        });
    },
    // 获取商品的详细信息
    allData() {
      this.$util
        .post("/demandPurchase/goodsDet", {
          goodsId: this.id,
        })
        .then((res) => {
          console.log(res);
          res.data.address = res.data.address.split(",");
          res.data.norms = res.data.norms.split(",");
          res.data.term = res.data.term.split(",");
          this.good = res.data;
          this.bannerList = res.data.banner.split(",");
          this.getEvaluate();
          this.getShopInfo();
        });
    },
    // 下标更改触发
    handleClick(tab) {
      this.current = tab.index;
    },
    // 保存草稿箱
    copy() {
      this.$util
        .post("/shop/copy", {
          goodsId: this.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$message.success("保存成功");
          } else {
            this.$message.error(res.message);
          }
        });
    },
    // 上架
    onShelves() {
      this.$util
        .post("/shop/upGoods", {
          goodsId: this.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$router.go(-1);
          }
        });
    },
    // 下架
    rack() {
      this.$util
        .post("/shop/downGoods", {
          goodsId: this.id,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$router.go(-1);
          }
        });
    },
    // 放回上一页
    goBack() {
      this.$router.go(-1);
    },
    close() {
      this.dialogVisible = false;
      this.allData();
    },
  },
};
</script>

<style lang="scss" scoped>
.head-main {
  width: 1200px;
  margin: 0 auto;
  padding: 30px 0;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-size: 18px;
  color: #2ea7e0;
  .head-bt {
    margin: 0 40px 0 80px;
    width: 130px;
    color: #fff;
    height: 50px;
    font-size: 16px;
    // font-weight: bold;
    background: #e40012;
  }
  .head-btn {
    width: 130px;
    color: #e40012;
    height: 50px;
    background: #fff;
    font-size: 16px;
    // font-weight: bold;
    border: 1px solid #e40012;
  }
  .keep {
    cursor: pointer;
  }
}
.detail {
  width: 1160px;
  padding: 20px;
  margin: 30px auto;
  border: 1px solid #eeeeee;
  display: flex;
}
.detail-img {
  width: 720px;
  height: 554px;
  // background: red;
  margin-right: 20px;
  img {
    width: 100%;
    height: 100%;
  }
}
.detail-info {
  flex: 1;
  .title {
    font-size: 22px;
    color: #222222;
    font-weight: bold;
  }
  .budget {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 30px 0 20px;
    color: #999999;
    font-size: 14px;
    font-weight: bold;
    span {
      color: #222222;
      font-size: 16px;
    }
    .red {
      color: #e40012;
    }
  }
  .shop-name {
    color: #222222;
    font-size: 16px;
    span {
      font-size: 14px;
      color: #999;
      margin-right: 60px;
    }
  }
  .introduction {
    display: flex;
    color: #999999;
    font-size: 14px;
    padding: 20px 0 30px;
    border-bottom: 1px solid #f1f1f1;
    div {
      flex: 1;
      margin-left: 35px;
      color: #222;
      font-size: 12px;
    }
  }
  .cycle {
    display: flex;
    font-size: 16px;
    color: #222;
    align-items: center;
    justify-content: space-between;
    margin: 20px 0;
    font-weight: bold;
    .shu {
      width: 2px;
      height: 24px;
      background: #f1f1f1;
    }
    span {
      color: #e40012;
      margin-left: 10px;
    }
  }
  .area {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #999;
    margin-bottom: 20px;
    .lable {
      width: 80px;
      margin-right: 30px;
    }
    .tags {
      flex: 1;
      display: flex;
      flex-wrap: wrap;
      div {
        padding: 6px 14px;
        border: 1px solid #dbdbdb;
        margin-right: 10px;
        border-radius: 4px;
        margin-bottom: 10px;
      }
      .active {
        padding: 6px 14px;
        border: 1px solid #e40012;
        color: #e40012;
      }
    }
  }
  .btn {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
  }
}
.tabs {
  width: 1200px;
  margin: 0 auto;
}
/deep/.el-tabs__item.is-active {
  color: #e40012;
}
/deep/.el-tabs__active-bar {
  background-color: #e40012 !important;
  width: 80px !important;
}
/deep/.el-tabs__item {
  font-size: 22px;
  color: #666666;
  width: 120px;
  text-align: center;
}
/deep/.el-tabs__item:hover {
  color: #e40012;
}
.shop-detail {
  padding: 20px;
  border: 1px solid #eeeeee;
  font-size: 14px;
  color: #222;
  margin-bottom: 30px;
}
.shop-content {
  // border: 1px solid #707070;
  // width: 1200px;
  // height: 400px;
  margin-top: 40px;
  /deep/ img {
    max-width: 100%;
  }
}

.menus {
  display: flex;
  justify-content: space-between;
  margin: 30px 0 50px;
  .box {
    width: 380px;
    color: #e40012;
    padding: 40px 0;
    font-size: 24px;
    text-align: center;
    // background: #e40012;
    border: 1px solid #e40012;
    div {
      font-size: 38px;
      font-weight: bold;
      margin-bottom: 15px;
    }
  }
}
.comment-box {
  width: 1200px;
  margin: 0 auto 20px;
}
.comment {
  display: flex;
  padding: 30px 20px;
  border-bottom: 1px solid #f1f1f1;
  img {
    width: 82px;
    height: 82px;
  }
  .comment-info {
    flex: 1;
    margin-left: 20px;
    font-size: 14px;
    color: #999999;
    .user-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: #222;
      .name {
        display: flex;
        align-items: center;
        div {
          margin-right: 20px;
        }
      }
    }
  }
}
.comment-content {
  margin: 13px 0 20px;
  font-size: 14px;
  color: #666;
}
.comment-serve {
  margin-bottom: 10px;
  span {
    color: #e40012;
  }
}
.wechat {
  font-size: 16px;
  text-align: center;
  color: #222;
}
.loser-btn {
  width: 180px;
  height: 50px;
  color: #fff;
  font-size: 18px;
  background: #dbdbdb;
  outline: none !important;
}
</style>
